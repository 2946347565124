import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import Colors from '../../../constants/Colors'
import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useIsInViewport from '../../../hooks/useIsInViewport'
import useOnMount from '../../../hooks/useOnMount'
import useWindowSize from '../../../hooks/useWindowSize'

import styles from './animated-wave.module.scss'

interface AnimatedWaveProps {
  className?: string
}

export default function AnimatedWave({ className }: AnimatedWaveProps) {
  const height = 100
  const width = useWindowSize().width

  const pathRef = useRef<SVGPathElement>(null)

  const [points, setPoints] = useState<
    Array<{ speed: number; offset: number; y: number }>
  >([])

  const { elementRef: svgRef, isInViewport } = useIsInViewport<SVGSVGElement>()

  const { start, stop } = useAnimationLoop((time) => {
    for (let index = 0; index < points.length; index += 1) {
      const point = points[index]
      point.y =
        height / 2 +
        (Math.cos(time.now * point.speed + Math.PI * index) / 1.5) *
          (height / 2)
    }

    const deltaX = width / points.length
    let d = `M ${-deltaX / 2} ${height / 2}`

    for (let index = 0; index < points.length; index += 1) {
      const point = points[index]
      const nextPoint = points[(index + 1) % points.length]

      const x = deltaX * index

      d += `Q ${x + deltaX / 2} ${point.y}, ${x + deltaX} ${
        (nextPoint.y + point.y) / 2
      }`
    }

    d += `L ${width} ${height} L ${0} ${height}`

    const pathEl = pathRef.current!
    pathEl.setAttribute('d', d)
  })

  useEffect(() => {
    const pointCount = Math.max(8, Math.round(width / 120))
    const points = []

    for (let i = 0; i < pointCount; i += 1) {
      points.push({
        speed: Math.random() * 0.0004,
        offset: Math.random(),
        y: 0,
      })
    }

    setPoints(points)
  }, [width])

  useEffect(() => {
    if (isInViewport) {
      start()
    } else {
      stop()
    }
  }, [isInViewport])

  useOnMount(() => {
    const svgEl = svgRef.current
    if (svgEl) {
      // React doesn't hydrate attributes with new values so we do it manually
      svgEl.setAttribute('viewBox', `0 0 ${width} ${height}`)
      svgEl.setAttribute('width', `${width}`)
      svgEl.setAttribute('height', `${height}`)
    }
  })

  return (
    <svg
      className={classNames(styles.svg, className)}
      viewBox={`0 0 ${width} ${height}`}
      height={height}
      width={width}
      ref={svgRef}
    >
      <path ref={pathRef} fill={Colors.MainBackground} />
    </svg>
  )
}
