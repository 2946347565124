import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { ReactElement } from 'react'

import { ContentfulEmployee } from '../../../@import-types/contentful/Employee.types'
import { PageColorDataProps } from '../../../constants/GlobalDOM'
import useImageFocalPoint from '../../../hooks/content/useImageFocalPoint'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import usePageTextColor from '../../../hooks/globalContent/usePageTextColor'
import useIsDesktop from '../../../hooks/useIsDesktop'
import AnimatedWave from '../../_elements/AnimatedWave'
import LayoutContainer from '../../_layouts/LayoutContainer'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './employee-hero.module.scss'

interface EmployeeHeroProps {
  employee: ContentfulEmployee

  ingressChildren?: ReactElement<{ className?: string }> | null
}

export default function EmployeeHero({
  employee,

  ingressChildren,
}: EmployeeHeroProps) {
  const {
    color,
    firstName,
    heroImage,
    heroImageFocalPoint,
    heroImageMobile,
    lastName,
    office,
    role,
  } = employee

  const krusaderLabel = useMicroCopy('text.krusader')

  const isDesktop = useIsDesktop()
  const renderWithMobileImage = !isDesktop && Boolean(heroImageMobile)

  const renderImage = renderWithMobileImage ? heroImageMobile : heroImage
  const hasImage = Boolean(renderImage)

  const textColor = usePageTextColor()

  const imageFocalPoint = useImageFocalPoint(heroImage, heroImageFocalPoint)
  const renderImageFocalPoint = renderWithMobileImage
    ? 'center'
    : imageFocalPoint

  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: !hasImage && color ? color : undefined,
        color: textColor,
      }}
      {...PageColorDataProps}
    >
      {renderImage && (
        <Img
          className={styles.image}
          fluid={renderImage.fluid}
          alt={renderImage.description || renderImage.title || ''}
          imgStyle={{ objectPosition: renderImageFocalPoint }}
        />
      )}

      <LayoutContainer className={styles.layoutContainer}>
        <div className={styles.upperContainer}>
          {role && <div className={styles.role}>{role}.</div>}

          {office && (
            <div className={styles.office}>
              {krusaderLabel}, {office.city}.
            </div>
          )}

          <HeroHeading className={styles.name}>
            <div>{firstName}</div>
            <div>{lastName}</div>
          </HeroHeading>
        </div>

        {ingressChildren &&
          React.cloneElement(ingressChildren, {
            className: classNames(
              ingressChildren.props.className,
              styles.ingress
            ),
          })}
      </LayoutContainer>

      <AnimatedWave className={styles.wave} />
    </div>
  )
}
