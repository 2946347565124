import { useMemo } from 'react'

import { ContentfulAsset } from '../../@import-types/contentful/Asset.types'
import { ImageFocalPoint } from '../../@import-types/contentful/general.types'

export default function useImageFocalPoint(
  image: ContentfulAsset | null | undefined,
  imageFocalPoint: ImageFocalPoint | null | undefined
) {
  return useMemo(() => {
    const imageDetails = image?.file?.details?.image
    const imageHeight = imageDetails?.height
    const imageWidth = imageDetails?.width
    const focalX = imageFocalPoint?.focalPoint?.x
    const focalY = imageFocalPoint?.focalPoint?.y

    const focalLeftPercentage = (focalX! / imageWidth!) * 100
    const focalTopPercentage = (focalY! / imageHeight!) * 100

    if (isNaN(focalLeftPercentage) || isNaN(focalTopPercentage)) {
      return '50% 50%'
    }

    return `${focalLeftPercentage}% ${focalTopPercentage}%`
  }, [image, imageFocalPoint])
}
